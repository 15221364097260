import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import React from 'react';

// import BuyMeACoffee from '@/src/components/BuyMeACoffee';
import PostHeader from '@/src/components/PostHeader';
import PostNavigator from '@/src/components/PostNavigator';
import ProjectCard from '@/src/components/ProjectCard';
import Seo from '@/src/components/Seo';
import Utterances from '@/src/components/Utterances';
import Layout from '@/src/layout';
import PostClass from '@/src/models/post';
import { MOBILE_MEDIA_QUERY } from '@/src/styles/const';
import { Post, SiteMetadata } from '@/src/type';

import * as S from './styled';

type PostTemplateProps = {
  location: Location;
  data: { prev: Post; next: Post; cur: Post; site: { siteMetadata: SiteMetadata }; markdownRemark: Post };
};

const PostTemplate: React.FC<PostTemplateProps> = ({ location, data }) => {
  const curPost = new PostClass(data.cur);
  const prevPost = data.prev && new PostClass(data.prev);
  const nextPost = data.next && new PostClass(data.next);
  const utterancesRepo = data.site.siteMetadata.comments.utterances.repo;

  const metaData = data.site.siteMetadata;
  const { projects } = metaData;

  return (
    <Layout location={location}>
      <Seo title={`MHLab | ${curPost?.title}`} description={curPost?.excerpt} />
      <PostHeader post={curPost} />
      <S.PostContent>
        <div className='markdown' dangerouslySetInnerHTML={{ __html: curPost.html }} />
      </S.PostContent>
      <S.BuyMeACoffeeWrapper>
        <StyledHR />
        <MadeServiceTitle>작은 개인광고 양해 바랍니다 ^^;;</MadeServiceTitle>
        <div>👇 주인장이 직접 만든 서비스 👇</div>
        <ProjectCardsWrapper>
          {projects.map((project, index) => (
            <ProjectCard key={index} project={project} />
          ))}
        </ProjectCardsWrapper>

        {/* <div>👇 도움이 되셨다면 👇</div> */}
        {/* <BuyMeACoffee /> */}
        <StyledHR />
      </S.BuyMeACoffeeWrapper>

      <PostNavigator prevPost={prevPost} nextPost={nextPost} />
      <Utterances repo={utterancesRepo} path={curPost.slug} />
    </Layout>
  );
};

export default PostTemplate;

export const pageQuery = graphql`
  query ($slug: String, $nextSlug: String, $prevSlug: String) {
    cur: markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      excerpt(pruneLength: 500, truncate: true)
      frontmatter {
        date(formatString: "YYYY.MM.DD")
        title
        categories
        emoji
      }
      fields {
        slug
      }
    }

    next: markdownRemark(fields: { slug: { eq: $nextSlug } }) {
      id
      html
      frontmatter {
        date(formatString: "YYYY.MM.DD")
        title
        categories
        emoji
      }
      fields {
        slug
      }
    }

    prev: markdownRemark(fields: { slug: { eq: $prevSlug } }) {
      id
      html
      frontmatter {
        date(formatString: "YYYY.MM.DD")
        title
        categories
        emoji
      }
      fields {
        slug
      }
    }

    site {
      siteMetadata {
        siteUrl
        comments {
          utterances {
            repo
          }
        }
        projects {
          title
          description
          techStack
          thumbnailUrl
          links {
            post
            github
            web
            googlePlay
            appStore
          }
        }
      }
    }
  }
`;

const StyledHR = styled.hr`
  border: none !important;
  border-top: 2px dashed black !important;
  margin: 20px 0 !important;
  width: 100%;
  display: block !important;
`;

const MadeServiceTitle = styled.div`
  font-size: 25px;
  font-weight: bold;
`;

const ProjectCardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;
  margin-top: 10px;
  row-gap: 24px;
  /* column-gap: 29px;
  margin-top: 60px;
  row-gap: 64px; */
  @media ${MOBILE_MEDIA_QUERY} {
    display: flex;
    flex-direction: column;
    gap: 26px;
    margin-top: 26px;
  }
`;
